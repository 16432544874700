import React from 'react';
import { motion } from 'framer-motion';
import { FaBriefcase, FaGraduationCap, FaAward, FaTools, FaCertificate, FaCode } from 'react-icons/fa';

const containerVariants = {
  hidden: { opacity: 0 },
  visible: { 
    opacity: 1,
    transition: { 
      staggerChildren: 0.1 
    } 
  }
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: { 
    y: 0, 
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 100
    }
  }
};

const TimelineEvent = ({ icon, title, subtitle, date, details }) => {
  return (
    <motion.div className="timeline-event" variants={itemVariants}>
      <div className="timeline-icon">{icon}</div>
      <motion.div 
        className="timeline-content"
        whileHover={{ scale: 1.02, boxShadow: '0px 5px 15px rgba(0,0,0,0.1)' }}
      >
        <h3>{title}</h3>
        {subtitle && <h4>{subtitle}</h4>}
        <p className="date">{date}</p>
        <ul>
          {details.map((detail, index) => (
            <li key={index}>{detail}</li>
          ))}
        </ul>
      </motion.div>
    </motion.div>
  );
};

const About = () => {
  return (
    <motion.div
      className="about-container"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.h2 className="section-title" variants={itemVariants}>About Me</motion.h2>
      <motion.div className="about-content" variants={itemVariants}>
        <motion.p className="about-description">
        MSc Web and Mobile Development Technologies student with a First Class BSc in Computer Science (QTS), specialising in front-end development. Proficient in CSS, HTML and  JavaScript. Demonstrates expertise in building responsive websites and mobile applications in collaborative environments. Excels under pressure, consistently delivering critical projects on time while taking a proactive and innovative approach to enhance web design functionality and able to leverage technical skills and contribute to cutting-edge web and mobile development projects.
        </motion.p>
      </motion.div>

      <motion.div className="timeline" variants={containerVariants}>
        <motion.h3 className="section-subtitle" variants={itemVariants}>
          <FaCode /> My Journey
        </motion.h3>
        <TimelineEvent
          icon={<FaBriefcase />}
          title="Front-end Developer Internship"
          subtitle="Traivis, Remote"
          date="Feb 2023 - May 2023"
          details={[
            "Collaborated on 3 website projects with a global team",
            "Created front-end designs using Tailwind CSS and Alpine.js",
            "Resolved complex debugging issues with 100% CEO satisfaction",
            "Implemented responsive design across devices"
          ]}
        />
        <TimelineEvent
          icon={<FaGraduationCap />}
          title="MSc Web and Mobile Development Technologies"
          subtitle="Northumbria University"
          date="Sept 2024 - Present"
          details={[
            "Focus: Advanced web technologies, mobile app development, and UI/UX design",
            "Curriculum covers: Full-stack web development, mobile platforms, cloud computing",
          ]}
        />
        <TimelineEvent
          icon={<FaGraduationCap />}
          title="BSc (Hons) Computer Science w/ Education and QTS"
          subtitle="University of East London"
          date="Sept 2021 - June 2024"
          details={[
            "First Class Honours",
            "Dissertation: AI-powered note-taking application for students",
            "Relevant Modules - Database System (85%), Web and Mobile Development (76%), Web Technologies (85%), Advanced Programming (81%) Software Development(93%), Advanced Topics in Computer Science (91%)",
          ]}
        />
        <TimelineEvent
          icon={<FaAward />}
          title="Achievements"
          date="2023 - 2024"
          details={[
            "Finalist for Best Project, University Dissertation Showcase (2024)",
            "Recipient of Funded Internship Scheme Award (2023)",
          ]}
        />
      </motion.div>

      <motion.div variants={containerVariants} className="skills-section">
        <motion.h3 className="section-subtitle" variants={itemVariants}>
          <FaTools /> Skills
        </motion.h3>
        <motion.div className="skills-grid" variants={containerVariants}>
          <motion.div className="skill-category" variants={itemVariants}>
            <h4>Languages</h4>
            <ul>
              <li>JavaScript (ES6+)</li>
              <li>Python</li>
              <li>Java</li>
              <li>HTML5</li>
              <li>CSS3</li>
            </ul>
          </motion.div>
          <motion.div className="skill-category" variants={itemVariants}>
            <h4>Frameworks/Libraries</h4>
            <ul>
              <li>React</li>
              <li>React Native</li>
              <li>Node.js</li>
              <li>Tailwind CSS</li>
              <li>Alpine.js</li>
            </ul>
          </motion.div>
        </motion.div>
      </motion.div>

      <motion.div variants={containerVariants} className="certifications-section">
        <motion.h3 className="section-subtitle" variants={itemVariants}>
          <FaCertificate /> Certifications
        </motion.h3>
        <motion.ul variants={containerVariants}>
          <motion.li variants={itemVariants}>Web Development Fundamentals | IBM | June 2024</motion.li>
          <motion.li variants={itemVariants}>Artificial Intelligence Fundamentals | IBM | May 2024</motion.li>
          <motion.li variants={itemVariants}>Certificate of BSC Membership | BCS, The Chartered Institute for IT | April 2024</motion.li>
        </motion.ul>
      </motion.div>
    </motion.div>
  );
};

export default About;