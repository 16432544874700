import React from 'react';
import { motion } from 'framer-motion';
import { FaGithub, FaLinkedin, FaMobile, FaCloud, FaArrowRight, FaTrophy } from 'react-icons/fa';
import { Typewriter } from 'react-simple-typewriter';
import profileImage from '../images/profilepicture.jpg';
import ParticlesBackground from './ParticlesBackground';
import confetti from 'canvas-confetti';

// Import the new EnhancedSkillsCodeSection
import EnhancedSkillsCodeSection from './EnhancedSkillsCodeSection';

// Data (unchanged)
const accomplishments = [
  {
    title: "Funded Internship Scheme Award",
    date: "June 2023",
    description: "Successfully completed internship program, gaining hands-on industry experience and professional skills.",
  },
  {
    title: "Finalist for Best Project, University Showcase",
    date: "April 2024",
    description: "Finalist for Best Project, University Dissertation Showcase",
  },
];

const featuredProjects = [
  {
    id: 1,
    title: "TakeNotes: AI-Powered Note-Taking App",
    description: "React Native app for students with AI-enhanced features.",
    icon: <FaMobile />,
    link: "https://github.com/HenryOnilude/note-taking-app"
  },
  {
    id: 2,
    title: "Weather App with Dynamic Backgrounds",
    description: "React-based weather app with location-based video backgrounds.",
    icon: <FaCloud />,
    link: "https://github.com/HenryOnilude/Weather-app"
  }
];

// Main Home component
const Home = () => {
  const launchConfetti = () => {
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  return (
    <div className="home-container">
      <ParticlesBackground />
      <HeroSection />
      <EnhancedSkillsCodeSection />
      <FeaturedProjectsSection projects={featuredProjects} />
      <AccomplishmentsSection accomplishments={accomplishments} launchConfetti={launchConfetti} />
    </div>
  );
};

// Hero Section component (unchanged)
const HeroSection = () => (
  <motion.section 
    className="hero-section"
    initial={{ opacity: 0, y: -50 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.8, ease: "easeOut" }}
  >
    <div className="hero-content">
      <motion.img
        src={profileImage}
        alt="Henry Onilude"
        className="profile-image"
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      />
      <div className="hero-text">
        <motion.h1 
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.2 }}
        >
          Henry Onilude
        </motion.h1>
        <motion.h2 
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.4 }}
        >
          <span role="img" aria-label="Coding">🤖</span>{' '}
          <Typewriter
            words={['Front-end Developer', 'Computer Science graduate', 'Web & Mobile Enthusiast', 'MSC Web & Mobile Development Student']}
            loop={0}
            cursor
            cursorStyle='|'
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
          />
        </motion.h2>
        <motion.p 
          initial={{ y: 20, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 0.6 }}
        >
          Passionate about creating responsive and user-friendly web and mobile applications
        </motion.p>
        <SocialLinks />
      </div>
    </div>
  </motion.section>
);

// Social Links component (unchanged)
const SocialLinks = () => (
  <motion.div 
    className="social-links"
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ delay: 0.8 }}
  >
    <motion.a 
      href="https://github.com/HenryOnilude" 
      target="_blank" 
      rel="noopener noreferrer"
      whileHover={{ scale: 1.2 }}
    >
      <FaGithub />
    </motion.a>
    <motion.a 
      href="https://www.linkedin.com/in/henry-onilude-349311137/" 
      target="_blank" 
      rel="noopener noreferrer"
      whileHover={{ scale: 1.2 }}
    >
      <FaLinkedin />
    </motion.a>
  </motion.div>
);

// Featured Projects Section component (unchanged)
const FeaturedProjectsSection = ({ projects }) => (
  <motion.section 
    className="featured-projects-section"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ delay: 1.4, duration: 0.8 }}
  >
    <h2 className="section-title">Featured Projects</h2>
    <div className="projects-grid">
      {projects.map((project, index) => (
        <motion.div 
          key={project.id}
          className="project-card"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 1.6 + index * 0.2, duration: 0.5 }}
          whileHover={{ scale: 1.05, boxShadow: '0px 0px 15px rgba(0,0,0,0.2)' }}
        >
          <div className="project-icon">
            {project.icon}
          </div>
          <div className="project-content">
            <h3>{project.title}</h3>
            <p>{project.description}</p>
            <a href={project.link} target="_blank" rel="noopener noreferrer" className="project-link">
              View on GitHub <FaArrowRight />
            </a>
          </div>
        </motion.div>
      ))}
    </div>
  </motion.section>
);

// Accomplishments Section component (unchanged)
const AccomplishmentsSection = ({ accomplishments, launchConfetti }) => (
  <motion.section 
    className="accomplishments-section"
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ delay: 1.8, duration: 0.8 }}
  >
    <h2 className="section-title">Accomplishments</h2>
    <div className="accomplishments-grid">
      {accomplishments.map((accomplishment, index) => (
        <motion.div 
          key={index}
          className="accomplishment-card"
          initial={{ y: 50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ delay: 2 + index * 0.2, duration: 0.5 }}
          whileHover={{ scale: 1.05, boxShadow: '0px 0px 8px rgba(0,0,0,0.3)' }}
          whileTap={{ scale: 0.95 }}
          onHoverStart={launchConfetti}
        >
          <div className="trophy-icon">
            <FaTrophy />
          </div>
          <div className="card-content">
            <h3>{accomplishment.title}</h3>
            <p className="date">{accomplishment.date}</p>
            <p className="description">{accomplishment.description}</p>
          </div>
        </motion.div>
      ))}
    </div>
  </motion.section>
);

export default Home;