import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { motion } from 'framer-motion';
import { FaReact, FaJs, FaHtml5, FaCss3, FaNodeJs, FaMobileAlt, FaPlay } from 'react-icons/fa';
import Prism from 'prismjs';
import 'prismjs/themes/prism-tomorrow.css';
import 'prismjs/components/prism-jsx';
import 'prismjs/components/prism-css';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-markup';

const AnimatedCodeEditor = ({ selectedSkillIndex, isDesktop }) => {
  const [typedCode, setTypedCode] = useState('');
  const [output, setOutput] = useState('');

  const techStack = useMemo(() => [
    {
      name: 'React',
      icon: <FaReact />,
      color: '#61DAFB',
      code: `import React, { useState } from 'react';
const Counter = () => {
  const [count, setCount] = useState(0);

  return (
    <div>
      <p>You clicked {count} times</p>
      <button onClick={() => setCount(count + 1)}>
        Click me
      </button>
    </div>
  );
};

export default Counter;`,
      language: 'jsx'
    },
    {
      name: 'JavaScript',
      icon: <FaJs />,
      color: '#F7DF1E',
      code: `function greet(name) {
  return \`Hello, \${name}!\`;
}

console.log(greet('World'));`,
      language: 'javascript'
    },
    {
      name: 'HTML5',
      icon: <FaHtml5 />,
      color: '#E34F26',
      code: `<!DOCTYPE html>
<html lang="en">
<head>
  <meta charset="UTF-8">
  <title>My Page</title>
</head>
<body>
  <h1>Welcome to my website!</h1>
  <!-- Add more content here -->
</body>
</html>`,
      language: 'markup'
    },
    {
      name: 'CSS3',
      icon: <FaCss3 />,
      color: '#1572B6',
      code: `.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
}`,
      language: 'css'
    },
    {
      name: 'Node.js',
      icon: <FaNodeJs />,
      color: '#339933',
      code: `const http = require('http');

const server = http.createServer((req, res) => {
  res.statusCode = 200;
  res.setHeader('Content-Type', 'text/plain');
  res.end('Hello World\\n');
});

server.listen(3000, () => {
  console.log('Server running on port 3000');
});`,
      language: 'javascript'
    },
    {
      name: 'React Native',
      icon: <FaMobileAlt />,
      color: '#61DAFB',
      code: `import React from 'react';
import { Text, View } from 'react-native';

const HelloWorldApp = () => {
  return (
    <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
      <Text>Hello, World!</Text>
    </View>
  )
}

export default HelloWorldApp;`,
      language: 'jsx'
    },
  ], []);

  useEffect(() => {
    setTypedCode(techStack[selectedSkillIndex].code);
    setOutput('');
  }, [selectedSkillIndex, techStack]);

  useEffect(() => {
    Prism.highlightAll();
  }, [typedCode]);

  const executeCode = useCallback(() => {
    const { language, name } = techStack[selectedSkillIndex];
    let result;

    switch (language) {
      case 'jsx':
        result = `${name} component rendered successfully`;
        break;
      case 'javascript':
        if (name === 'Node.js') {
          result = 'Server started on port 3000';
        } else {
          result = 'Hello, World!';
        }
        break;
      case 'markup':
        result = 'HTML structure rendered successfully';
        break;
      case 'css':
        result = 'Styles applied successfully';
        break;
      default:
        result = 'Code executed successfully';
    }

    setOutput(result);
  }, [selectedSkillIndex, techStack]);

  return (
    <div className={`animated-code-editor ${isDesktop ? 'desktop' : 'mobile'}`}>
      <div className="editor-window">
        <div className="editor-header">
          <div className="tech-icon" style={{ color: techStack[selectedSkillIndex].color }}>
            {techStack[selectedSkillIndex].icon}
          </div>
          <h3>{techStack[selectedSkillIndex].name}</h3>
          <button onClick={executeCode} className="run-code-button">
            <FaPlay /> Run Code
          </button>
        </div>
        <motion.pre
          className="code-area"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          <code className={`language-${techStack[selectedSkillIndex].language}`}>
            {typedCode}
          </code>
        </motion.pre>
        {output && (
          <div className="output-area">
            <h4>Output:</h4>
            <pre>{output}</pre>
          </div>
        )}
      </div>
    </div>
  );
};

export default AnimatedCodeEditor;