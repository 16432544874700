import React, { useState, useEffect, useCallback } from 'react';
import { motion } from 'framer-motion';
import { FaReact, FaJs, FaHtml5, FaCss3, FaNodeJs, FaMobileAlt } from 'react-icons/fa';
import AnimatedCodeEditor from './AnimatedCodeEditor';

const skills = [
  { name: 'React', icon: <FaReact />, color: '#61DAFB' },
  { name: 'JavaScript', icon: <FaJs />, color: '#F7DF1E' },
  { name: 'HTML5', icon: <FaHtml5 />, color: '#E34F26' },
  { name: 'CSS3', icon: <FaCss3 />, color: '#1572B6' },
  { name: 'Node.js', icon: <FaNodeJs />, color: '#339933' },
  { name: 'React Native', icon: <FaMobileAlt />, color: '#61DAFB' },
];

const EnhancedSkillsCodeSection = () => {
  const [selectedSkillIndex, setSelectedSkillIndex] = useState(0);
  const [isDesktop, setIsDesktop] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const autoTransition = useCallback(() => {
    setSelectedSkillIndex((prevIndex) => (prevIndex + 1) % skills.length);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(autoTransition, 10000); // Change skill every 10 seconds

    return () => clearInterval(intervalId);
  }, [autoTransition]);

  return (
    <section className={`enhanced-skills-code-section ${isDesktop ? '' : 'mobile'}`}>
      <h2 className="section-title">Skills & Code Samples</h2>
      <div className="skills-code-container">
        <div className="tech-stack-section">
          <h3>Tech Stack</h3>
          <div className="tech-stack-grid">
            {skills.map((skill, index) => (
              <motion.div
                key={skill.name}
                className={`tech-item ${selectedSkillIndex === index ? 'active' : ''}`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setSelectedSkillIndex(index)}
              >
                {React.cloneElement(skill.icon, { color: skill.color, size: isDesktop ? 40 : 30 })}
                <span>{skill.name}</span>
              </motion.div>
            ))}
          </div>
        </div>
        <div className={`code-sample-container ${isDesktop ? '' : 'mobile'}`}>
          <AnimatedCodeEditor selectedSkillIndex={selectedSkillIndex} isDesktop={isDesktop} />
        </div>
      </div>
    </section>
  );
};

export default EnhancedSkillsCodeSection;