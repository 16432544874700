import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { useTheme } from '../contexts/ThemeContext';
import { FaHome, FaUser, FaProjectDiagram, FaCogs, FaGithub, FaEnvelope, FaLinkedin } from 'react-icons/fa';

const Navigation = () => {
  const { theme } = useTheme();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <nav className={`navigation ${theme}`}>
      <ul>
        <li><NavLink to="/"><FaHome /> Home</NavLink></li>
        <li><NavLink to="/about"><FaUser /> About</NavLink></li>
        <li><NavLink to="/projects"><FaProjectDiagram /> Projects</NavLink></li>
        <li><NavLink to="/skills"><FaCogs /> Skills</NavLink></li>
        {!isMobile && (
          <>
            <li><a href="https://github.com/HenryOnilude" target="_blank" rel="noopener noreferrer"><FaGithub /> GitHub</a></li>
            <li><a href="https://www.linkedin.com/in/henry-onilude-349311137/" target="_blank" rel="noopener noreferrer"><FaLinkedin /> LinkedIn</a></li>
            <li><a href="mailto:henryonilude@hotmail.co.uk"><FaEnvelope /> Email</a></li>
          </>
        )}
      </ul>
    </nav>
  );
};

export default Navigation;