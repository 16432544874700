import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from './contexts/ThemeContext';
import Navigation from './components/Navigation';
import Home from './components/Home';
import About from './components/About';
import Projects from './components/Projects';
import Skills from './components/Skills';
import ParticlesBackground from './components/ParticlesBackground';
import Loader from './components/Loader';
import './App.css';

const App = () => {
  // State to manage loading screen
  const [loading, setLoading] = useState(true);

  // Effect to simulate initial loading time
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    // Cleanup timer on component unmount
    return () => clearTimeout(timer);
  }, []);

  // Render loader while content is being prepared
  if (loading) {
    return <Loader />;
  }

  return (
    <ThemeProvider>
      <Router>
        <div className="app">
          {/* Background particle effect */}
          <ParticlesBackground />
          
          {/* Navigation component */}
          <Navigation />
          
          {/* Main content area */}
          <main className="main-content">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/skills" element={<Skills />} />
              {/* Add more routes as needed */}
            </Routes>
          </main>
        </div>
      </Router>
    </ThemeProvider>
  );
};

export default App;