import React from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '../contexts/ThemeContext';
import { FaGithub, FaTools, FaFeatherAlt } from 'react-icons/fa';
import takenotesImage from '../images/takenotesprofilepicture.jpeg';
import weatherAppImage from '../images/WeatherAppPicture.jpg';

const projects = [
  {
    id: 1,
    title: "TakeNotes: AI-Powered Note-Taking App",
    description: "This innovative application leverages artificial intelligence to enhance the note-taking experience, providing smart organisation and content suggestions.",
    image: takenotesImage,
    githubLink: "https://github.com/HenryOnilude/note-taking-app",
    features: [
      "User Authentication",
      "CRUD Operations",
      "AI-Powered Features (Text Recognition, Image Analysis, Text Summarisation)",
      "Customisation Options",
      "Sharing and Collaboration Tools"
    ],
    techStack: ["React Native", "Expo", "Google Cloud Vision API", "AsyncStorage"]
  },
  {
    id: 2,
    title: "Weather App with Dynamic Video Backgrounds",
    description: "This application fetches and displays current weather data for a user's location or a searched location using the OpenWeatherMap API.",
    image: weatherAppImage,
    githubLink: "https://github.com/HenryOnilude/Weather-app",
    features: [
      "Geolocation",
      "City-based Weather Search",
      "Real-time Weather Data Display",
      "Dynamic Video Backgrounds",
      "Responsive Design"
    ],
    techStack: ["React", "OpenWeatherMap API", "CSS", "HTML5 Video"]
  }
];

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2
    }
  }
};

const projectVariants = {
  hidden: { y: 50, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 100,
      damping: 12
    }
  }
};

const Projects = () => {
  const { theme } = useTheme();

  return (
    <motion.div
      className={`projects-container ${theme}`}
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.h2
        className="section-title"
        variants={projectVariants}
      >
        My Projects
      </motion.h2>
      <div className="projects-grid">
        {projects.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </div>
    </motion.div>
  );
};

const ProjectCard = ({ project }) => {
  return (
    <motion.div
      className="project-card"
      variants={projectVariants}
      whileHover={{ scale: 1.02, boxShadow: '0px 5px 15px rgba(0,0,0,0.1)' }}
      whileTap={{ scale: 0.98 }}
    >
      <div className="project-image-container">
        <img src={project.image} alt={project.title} className="project-image" />
      </div>
      <div className="project-content">
        <h3 className="project-title">{project.title}</h3>
        <p className="project-description">{project.description}</p>
        <ProjectFeatures features={project.features} />
        <TechStack techStack={project.techStack} />
        <ProjectLinks githubLink={project.githubLink} />
      </div>
    </motion.div>
  );
};

const ProjectFeatures = ({ features }) => (
  <div className="project-features">
    <h4><FaFeatherAlt /> Key Features</h4>
    <ul>
      {features.map((feature, index) => (
        <motion.li 
          key={index}
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: index * 0.1 }}
        >
          {feature}
        </motion.li>
      ))}
    </ul>
  </div>
);

const TechStack = ({ techStack }) => (
  <div className="tech-stack">
    <h4><FaTools /> Tech Stack</h4>
    <ul>
      {techStack.map((tech, index) => (
        <motion.li 
          key={index}
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ delay: index * 0.1 }}
        >
          {tech}
        </motion.li>
      ))}
    </ul>
  </div>
);

const ProjectLinks = ({ githubLink }) => (
  <div className="project-links">
    <motion.a 
      href={githubLink} 
      target="_blank" 
      rel="noopener noreferrer" 
      className="project-link github"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <FaGithub /> GitHub Repo
    </motion.a>
  </div>
);

export default Projects;