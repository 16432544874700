import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../contexts/ThemeContext';
import { 
  FaJs, FaPython, FaJava, FaHtml5, FaCss3, FaReact, FaNodeJs, 
  FaDatabase, FaGitAlt,  FaMedal, FaCalendarAlt, 
  FaUniversity, FaTools, FaCertificate, FaCode, FaLayerGroup,
  FaMobile, FaWordpress, FaPalette, FaWind, FaMountain, FaRocket 
} from 'react-icons/fa';

const skillCategories = [
  {
    name: "Languages",
    icon: <FaCode />,
    skills: [
      { name: "JavaScript", icon: <FaJs />, description: "Proficient in ES6+, async programming", color: "#f7df1e" },
      { name: "Python", icon: <FaPython />, description: "Data analysis, scripting", color: "#3776ab" },
      { name: "Java", icon: <FaJava />, description: "Object-oriented programming", color: "#007396" },
      { name: "HTML", icon: <FaHtml5 />, description: "Semantic markup, accessibility best practices", color: "#e34c26" },
      { name: "CSS", icon: <FaCss3 />, description: "Responsive design, CSS Grid, Flexbox", color: "#264de4" },
    ]
  },
  {
    name: "Frameworks/Libraries",
    icon: <FaLayerGroup />,
    skills: [
      { name: "React", icon: <FaReact />, description: "Component-based architecture, state management", color: "#61dafb" },
      { name: "Node.js", icon: <FaNodeJs />, description: "Server-side JavaScript, RESTful APIs", color: "#68a063" },
      { name: "React Native", icon: <FaMobile />, description: "Cross-platform mobile app development", color: "#61dafb" },
      { name: "WordPress", icon: <FaWordpress />, description: "CMS, theme and plugin development", color: "#21759b" },
      { name: "Wix", icon: <FaPalette />, description: "Website building, customisation", color: "#faad4d" },
      { name: "Tailwind CSS", icon: <FaWind />, description: "Utility-first CSS framework", color: "#06B6D4" },
      { name: "Alpine.js", icon: <FaMountain />, description: "Lightweight JavaScript framework", color: "#77C1D2" },
    ]
  },
  {
    name: "Tools",
    icon: <FaTools />,
    skills: [
      { name: "Git", icon: <FaGitAlt />, description: "Version control, collaborative development", color: "#f34f29" },
      { name: "MongoDB", icon: <FaDatabase />, description: "NoSQL database, data modeling", color: "#4db33d" },
      { name: "Firebase", icon: <FaDatabase />, description: "Real-time database, authentication", color: "#ffca28" },
      { name: "Netlify", icon: <FaRocket />, description: "Continuous deployment, serverless functions", color: "#00C7B7" },
    ]
  }
];

const certifications = [
  { name: "Web Development Fundamentals", issuer: "IBM", date: "June 2024" },
  { name: "Artificial Intelligence Fundamentals", issuer: "IBM", date: "May 2024" },
  { name: "Certificate of BSC Membership", issuer: "BCS, The Chartered Institute for IT", date: "April 2024" },
];

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 100
    }
  }
};

const Skills = () => {
  const { theme } = useTheme();
  const [hoveredSkill, setHoveredSkill] = useState(null);

  return (
    <motion.div
      className={`skills-container ${theme}`}
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.h2 variants={itemVariants} className="section-title">My Skills</motion.h2>
      
      <div className="skills-layout">
        <div className="cube-section">
          <AnimatedCube skills={skillCategories.flatMap(category => category.skills)} setHoveredSkill={setHoveredSkill} />
          
          <AnimatePresence>
            {hoveredSkill && (
              <motion.div 
                className="skill-details"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 20 }}
              >
                <h3>{hoveredSkill.name}</h3>
                <p>{hoveredSkill.description}</p>
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        <div className="categories-section">
          {skillCategories.map((category, index) => (
            <SkillCategory key={index} category={category} />
          ))}
        </div>
      </div>
      
      <Certifications certifications={certifications} />
    </motion.div>
  );
};

const AnimatedCube = ({ skills, setHoveredSkill }) => {
  return (
    <div className="cube-container">
      <div className="cube">
        {skills.slice(0, 6).map((skill, index) => (
          <div 
            key={skill.name} 
            className={`cube-face cube-face-${index + 1}`}
            style={{ backgroundColor: skill.color }}
            onMouseEnter={() => setHoveredSkill(skill)}
            onMouseLeave={() => setHoveredSkill(null)}
          >
            <div className="cube-face-content">
              {skill.icon}
              <h3>{skill.name}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const SkillCategory = ({ category }) => {
  return (
    <motion.div className="skill-category" variants={itemVariants}>
      <h3 className="category-title">
        {category.icon}
        <span>{category.name}</span>
      </h3>
      <div className="skills-grid">
        {category.skills.map((skill, index) => (
          <SkillItem key={index} skill={skill} />
        ))}
      </div>
    </motion.div>
  );
};

const SkillItem = ({ skill }) => {
  return (
    <motion.div
      className="skill-item"
      whileHover={{ scale: 1.05, boxShadow: "0px 5px 15px rgba(0,0,0,0.1)" }}
      whileTap={{ scale: 0.95 }}
    >
      <div className="skill-icon" style={{ color: skill.color }}>{skill.icon}</div>
      <div className="skill-content">
        <span className="skill-name">{skill.name}</span>
        <p className="skill-description">{skill.description}</p>
      </div>
    </motion.div>
  );
};

const Certifications = ({ certifications }) => {
  return (
    <motion.div className="certifications-container" variants={itemVariants}>
      <h2 className="section-title">
        <FaCertificate />
        <span>Certifications</span>
      </h2>
      <div className="certifications-grid">
        {certifications.map((cert, index) => (
          <CertificationCard key={index} cert={cert} index={index} />
        ))}
      </div>
    </motion.div>
  );
};

const CertificationCard = ({ cert, index }) => {
  return (
    <motion.div
      className="certification-card"
      variants={itemVariants}
      whileHover={{ scale: 1.05, boxShadow: "0px 10px 30px rgba(0,0,0,0.1)" }}
    >
      <div className="cert-icon">
        <FaMedal size={40} color="#FFD700" />
      </div>
      <h3>{cert.name}</h3>
      <div className="cert-details">
        <p><FaUniversity /> {cert.issuer}</p>
        <p><FaCalendarAlt /> {cert.date}</p>
      </div>
      <div className="cert-shine"></div>
    </motion.div>
  );
};

export default Skills;